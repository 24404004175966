// source: domain/enumeration/service_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ServiceType', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ServiceType = {
  SERVICE_TYPE_UNSPECIFIED: 0,
  SERVICE_TYPE_MESSAGE: 1,
  SERVICE_TYPE_TELEPHONE: 2,
  SERVICE_TYPE_VIDEO: 3,
  SERVICE_TYPE_OFFER: 4
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
