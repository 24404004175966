// source: domain/enumeration/provider_coupon_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus = {
  PROVIDER_COUPON_STATUS_UNSPECIFIED: 0,
  PROVIDER_COUPON_STATUS_RESERVED: 1,
  PROVIDER_COUPON_STATUS_DELIVERED: 2,
  PROVIDER_COUPON_STATUS_CLOSED: 3
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
