// source: domain/enumeration/provider_coupon_target.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget = {
  PROVIDER_COUPON_TARGET_UNSPECIFIED: 0,
  PROVIDER_COUPON_TARGET_FOLLOWERS: 1,
  PROVIDER_COUPON_TARGET_HIGHEST_AMOUNT_BUYERS: 2,
  PROVIDER_COUPON_TARGET_MOST_PURCHASES_BUYERS: 3,
  PROVIDER_COUPON_TARGET_LATEST_BUYERS: 4,
  PROVIDER_COUPON_TARGET_FAVORITED_SERVICE: 5
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
