// source: domain/enumeration/provider_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ProviderStatus', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ProviderStatus = {
  PROVIDER_STATUS_UNSPECIFIED: 0,
  PROVIDER_STATUS_WAITING_PHONE: 1,
  PROVIDER_STATUS_PHONE_TALKING: 2,
  PROVIDER_STATUS_NOT_ON_SEAT: 3
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
