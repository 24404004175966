// source: domain/provider_coupon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var domain_enumeration_certification_type_pb = require('../domain/enumeration/certification_type_pb.js');
goog.object.extend(proto, domain_enumeration_certification_type_pb);
var domain_enumeration_provider_coupon_status_pb = require('../domain/enumeration/provider_coupon_status_pb.js');
goog.object.extend(proto, domain_enumeration_provider_coupon_status_pb);
var domain_enumeration_provider_coupon_target_pb = require('../domain/enumeration/provider_coupon_target_pb.js');
goog.object.extend(proto, domain_enumeration_provider_coupon_target_pb);
var domain_enumeration_provider_coupon_type_pb = require('../domain/enumeration/provider_coupon_type_pb.js');
goog.object.extend(proto, domain_enumeration_provider_coupon_type_pb);
var domain_enumeration_provider_rank_pb = require('../domain/enumeration/provider_rank_pb.js');
goog.object.extend(proto, domain_enumeration_provider_rank_pb);
var domain_enumeration_provider_status_pb = require('../domain/enumeration/provider_status_pb.js');
goog.object.extend(proto, domain_enumeration_provider_status_pb);
var domain_enumeration_service_class_pb = require('../domain/enumeration/service_class_pb.js');
goog.object.extend(proto, domain_enumeration_service_class_pb);
var domain_enumeration_service_reception_status_pb = require('../domain/enumeration/service_reception_status_pb.js');
goog.object.extend(proto, domain_enumeration_service_reception_status_pb);
var domain_enumeration_service_type_pb = require('../domain/enumeration/service_type_pb.js');
goog.object.extend(proto, domain_enumeration_service_type_pb);
var domain_provider_favorite_service_pb = require('../domain/provider_favorite_service_pb.js');
goog.object.extend(proto, domain_provider_favorite_service_pb);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponDetailDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponDetailDomain.DiscountDetailCase', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponListItemDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponListItemDomain.DiscountDetailCase', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.coconala.protobuf.domain.ProviderCouponDetailDomain.oneofGroups_);
};
goog.inherits(proto.coconala.protobuf.domain.ProviderCouponDetailDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.ProviderCouponDetailDomain.displayName = 'proto.coconala.protobuf.domain.ProviderCouponDetailDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.coconala.protobuf.domain.ProviderCouponListItemDomain.oneofGroups_);
};
goog.inherits(proto.coconala.protobuf.domain.ProviderCouponListItemDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.ProviderCouponListItemDomain.displayName = 'proto.coconala.protobuf.domain.ProviderCouponListItemDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.displayName = 'proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.displayName = 'proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.displayName = 'proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.displayName = 'proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.DiscountDetailCase = {
  DISCOUNT_DETAIL_NOT_SET: 0,
  DISCOUNT_RATE: 5,
  DISCOUNT_AMOUNT: 6
};

/**
 * @return {proto.coconala.protobuf.domain.ProviderCouponDetailDomain.DiscountDetailCase}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getDiscountDetailCase = function() {
  return /** @type {proto.coconala.protobuf.domain.ProviderCouponDetailDomain.DiscountDetailCase} */(jspb.Message.computeOneofCase(this, proto.coconala.protobuf.domain.ProviderCouponDetailDomain.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.ProviderCouponDetailDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    providerCouponType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    providerCouponTarget: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serviceClass: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discountRate: (f = msg.getDiscountRate()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountAmount: (f = msg.getDiscountAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    minimumAmount: (f = msg.getMinimumAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    deliveryDate: (f = msg.getDeliveryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expireDate: (f = msg.getExpireDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    providerCouponStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    providerFavoriteServiceDomain: (f = msg.getProviderFavoriteServiceDomain()) && domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.ProviderCouponDetailDomain;
  return proto.coconala.protobuf.domain.ProviderCouponDetailDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType} */ (reader.readEnum());
      msg.setProviderCouponType(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (reader.readEnum());
      msg.setProviderCouponTarget(value);
      break;
    case 4:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceClass} */ (reader.readEnum());
      msg.setServiceClass(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountRate(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountAmount(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinimumAmount(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryDate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireDate(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 11:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus} */ (reader.readEnum());
      msg.setProviderCouponStatus(value);
      break;
    case 12:
      var value = new domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain;
      reader.readMessage(value,domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain.deserializeBinaryFromReader);
      msg.setProviderFavoriteServiceDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.ProviderCouponDetailDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProviderCouponTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getServiceClass();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDiscountRate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMinimumAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpireDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getProviderFavoriteServiceDomain();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional enumeration.ProviderCouponType provider_coupon_type = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getProviderCouponType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setProviderCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional enumeration.ProviderCouponTarget provider_coupon_target = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getProviderCouponTarget = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setProviderCouponTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional enumeration.ServiceClass service_class = 4;
 * @return {!proto.coconala.protobuf.domain.enumeration.ServiceClass}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getServiceClass = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceClass} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ServiceClass} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setServiceClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value discount_rate = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getDiscountRate = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setDiscountRate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.coconala.protobuf.domain.ProviderCouponDetailDomain.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearDiscountRate = function() {
  return this.setDiscountRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasDiscountRate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value discount_amount = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getDiscountAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.coconala.protobuf.domain.ProviderCouponDetailDomain.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearDiscountAmount = function() {
  return this.setDiscountAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasDiscountAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value minimum_amount = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getMinimumAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setMinimumAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearMinimumAmount = function() {
  return this.setMinimumAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasMinimumAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getDeliveryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setDeliveryDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearDeliveryDate = function() {
  return this.setDeliveryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasDeliveryDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp expire_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getExpireDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearExpireDate = function() {
  return this.setExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasExpireDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue message = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional enumeration.ProviderCouponStatus provider_coupon_status = 11;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getProviderCouponStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setProviderCouponStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional ProviderFavoriteServiceDomain provider_favorite_service_domain = 12;
 * @return {?proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.getProviderFavoriteServiceDomain = function() {
  return /** @type{?proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain} */ (
    jspb.Message.getWrapperField(this, domain_provider_favorite_service_pb.ProviderFavoriteServiceDomain, 12));
};


/**
 * @param {?proto.coconala.protobuf.domain.ProviderFavoriteServiceDomain|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.setProviderFavoriteServiceDomain = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.clearProviderFavoriteServiceDomain = function() {
  return this.setProviderFavoriteServiceDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDetailDomain.prototype.hasProviderFavoriteServiceDomain = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.DiscountDetailCase = {
  DISCOUNT_DETAIL_NOT_SET: 0,
  DISCOUNT_RATE: 6,
  DISCOUNT_AMOUNT: 7
};

/**
 * @return {proto.coconala.protobuf.domain.ProviderCouponListItemDomain.DiscountDetailCase}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getDiscountDetailCase = function() {
  return /** @type {proto.coconala.protobuf.domain.ProviderCouponListItemDomain.DiscountDetailCase} */(jspb.Message.computeOneofCase(this, proto.coconala.protobuf.domain.ProviderCouponListItemDomain.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.ProviderCouponListItemDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    providerCouponType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    providerCouponStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    providerCouponTarget: jspb.Message.getFieldWithDefault(msg, 4, 0),
    serviceClass: jspb.Message.getFieldWithDefault(msg, 5, 0),
    discountRate: (f = msg.getDiscountRate()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountAmount: (f = msg.getDiscountAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    deliveryDate: (f = msg.getDeliveryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expireDate: (f = msg.getExpireDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    targetUserCount: (f = msg.getTargetUserCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    usedCount: (f = msg.getUsedCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isDelivered: (f = msg.getIsDelivered()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.ProviderCouponListItemDomain;
  return proto.coconala.protobuf.domain.ProviderCouponListItemDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType} */ (reader.readEnum());
      msg.setProviderCouponType(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus} */ (reader.readEnum());
      msg.setProviderCouponStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (reader.readEnum());
      msg.setProviderCouponTarget(value);
      break;
    case 5:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceClass} */ (reader.readEnum());
      msg.setServiceClass(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountRate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountAmount(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryDate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireDate(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTargetUserCount(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUsedCount(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDelivered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.ProviderCouponListItemDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getProviderCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProviderCouponStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProviderCouponTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getServiceClass();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDiscountRate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpireDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTargetUserCount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUsedCount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsDelivered();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional enumeration.ProviderCouponType provider_coupon_type = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getProviderCouponType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponType} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setProviderCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional enumeration.ProviderCouponStatus provider_coupon_status = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getProviderCouponStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponStatus} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setProviderCouponStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional enumeration.ProviderCouponTarget provider_coupon_target = 4;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getProviderCouponTarget = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderCouponTarget} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setProviderCouponTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional enumeration.ServiceClass service_class = 5;
 * @return {!proto.coconala.protobuf.domain.enumeration.ServiceClass}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getServiceClass = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceClass} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ServiceClass} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setServiceClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Int32Value discount_rate = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getDiscountRate = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setDiscountRate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.coconala.protobuf.domain.ProviderCouponListItemDomain.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearDiscountRate = function() {
  return this.setDiscountRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasDiscountRate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value discount_amount = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getDiscountAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.coconala.protobuf.domain.ProviderCouponListItemDomain.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearDiscountAmount = function() {
  return this.setDiscountAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasDiscountAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getDeliveryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setDeliveryDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearDeliveryDate = function() {
  return this.setDeliveryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasDeliveryDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp expire_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getExpireDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearExpireDate = function() {
  return this.setExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasExpireDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value target_user_count = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getTargetUserCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setTargetUserCount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearTargetUserCount = function() {
  return this.setTargetUserCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasTargetUserCount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value used_count = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getUsedCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setUsedCount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearUsedCount = function() {
  return this.setUsedCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasUsedCount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.BoolValue is_delivered = 12;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.getIsDelivered = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 12));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.setIsDelivered = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponListItemDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.clearIsDelivered = function() {
  return this.setIsDelivered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponListItemDomain.prototype.hasIsDelivered = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    userName: (f = msg.getUserName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    userIconPath: (f = msg.getUserIconPath()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    followedDate: (f = msg.getFollowedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastTradingDate: (f = msg.getLastTradingDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastServiceName: (f = msg.getLastServiceName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalPurchaseAmount: (f = msg.getTotalPurchaseAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    tradingCount: (f = msg.getTradingCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    favoritedDate: (f = msg.getFavoritedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain;
  return proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserIconPath(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFollowedDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastTradingDate(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastServiceName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalPurchaseAmount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTradingCount(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFavoritedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserIconPath();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFollowedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastTradingDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastServiceName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalPurchaseAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTradingCount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFavoritedDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value user_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getUserId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue user_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getUserName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setUserName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearUserName = function() {
  return this.setUserName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasUserName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue user_icon_path = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getUserIconPath = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setUserIconPath = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearUserIconPath = function() {
  return this.setUserIconPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasUserIconPath = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp followed_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getFollowedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setFollowedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearFollowedDate = function() {
  return this.setFollowedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasFollowedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp last_trading_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getLastTradingDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setLastTradingDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearLastTradingDate = function() {
  return this.setLastTradingDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasLastTradingDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue last_service_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getLastServiceName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setLastServiceName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearLastServiceName = function() {
  return this.setLastServiceName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasLastServiceName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value total_purchase_amount = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getTotalPurchaseAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setTotalPurchaseAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearTotalPurchaseAmount = function() {
  return this.setTotalPurchaseAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasTotalPurchaseAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value trading_count = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getTradingCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setTradingCount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearTradingCount = function() {
  return this.setTradingCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasTradingCount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp favorited_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.getFavoritedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.setFavoritedDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.clearFavoritedDate = function() {
  return this.setFavoritedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetUserDomain.prototype.hasFavoritedDate = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    providerRank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userIconPath: (f = msg.getUserIconPath()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain;
  return proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderRank} */ (reader.readEnum());
      msg.setProviderRank(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserIconPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProviderRank();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUserIconPath();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional enumeration.ProviderRank provider_rank = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderRank}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.getProviderRank = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderRank} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderRank} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.setProviderRank = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue user_icon_path = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.getUserIconPath = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.setUserIconPath = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.clearUserIconPath = function() {
  return this.setUserIconPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponProviderInfoDomain.prototype.hasUserIconPath = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    serviceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serviceThumbnailUrl: (f = msg.getServiceThumbnailUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    ratingIndicator: (f = msg.getRatingIndicator()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
    salesCount: (f = msg.getSalesCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountedPrice: (f = msg.getDiscountedPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    certificationType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    providerStatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    serviceReceptionStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    unitTime: (f = msg.getUnitTime()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain;
  return proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceType} */ (reader.readEnum());
      msg.setServiceType(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setServiceThumbnailUrl(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setRatingIndicator(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setSalesCount(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountedPrice(value);
      break;
    case 9:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.CertificationType} */ (reader.readEnum());
      msg.setCertificationType(value);
      break;
    case 10:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderStatus} */ (reader.readEnum());
      msg.setProviderStatus(value);
      break;
    case 11:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceReceptionStatus} */ (reader.readEnum());
      msg.setServiceReceptionStatus(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUnitTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getServiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getServiceThumbnailUrl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRatingIndicator();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getSalesCount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountedPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCertificationType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getProviderStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getServiceReceptionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getUnitTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue title = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional enumeration.ServiceType service_type = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.ServiceType}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getServiceType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ServiceType} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setServiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue service_thumbnail_url = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getServiceThumbnailUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setServiceThumbnailUrl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearServiceThumbnailUrl = function() {
  return this.setServiceThumbnailUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasServiceThumbnailUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.FloatValue rating_indicator = 5;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getRatingIndicator = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 5));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setRatingIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearRatingIndicator = function() {
  return this.setRatingIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasRatingIndicator = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value sales_count = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getSalesCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setSalesCount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearSalesCount = function() {
  return this.setSalesCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasSalesCount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value price = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value discounted_price = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getDiscountedPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setDiscountedPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearDiscountedPrice = function() {
  return this.setDiscountedPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasDiscountedPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional enumeration.CertificationType certification_type = 9;
 * @return {!proto.coconala.protobuf.domain.enumeration.CertificationType}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getCertificationType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.CertificationType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.CertificationType} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setCertificationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional enumeration.ProviderStatus provider_status = 10;
 * @return {!proto.coconala.protobuf.domain.enumeration.ProviderStatus}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getProviderStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ProviderStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ProviderStatus} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setProviderStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional enumeration.ServiceReceptionStatus service_reception_status = 11;
 * @return {!proto.coconala.protobuf.domain.enumeration.ServiceReceptionStatus}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getServiceReceptionStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.ServiceReceptionStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.ServiceReceptionStatus} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setServiceReceptionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional google.protobuf.Int32Value unit_time = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.getUnitTime = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.setUnitTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.clearUnitTime = function() {
  return this.setUnitTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponTargetServiceDomain.prototype.hasUnitTime = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountRate: (f = msg.getDiscountRate()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountAmount: (f = msg.getDiscountAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    discountedPrice: (f = msg.getDiscountedPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain;
  return proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountRate(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountAmount(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDiscountedPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDiscountedPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value discount_rate = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.getDiscountRate = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.setDiscountRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.clearDiscountRate = function() {
  return this.setDiscountRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.hasDiscountRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value discount_amount = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.getDiscountAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.clearDiscountAmount = function() {
  return this.setDiscountAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.hasDiscountAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value discounted_price = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.getDiscountedPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
*/
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.setDiscountedPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain} returns this
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.clearDiscountedPrice = function() {
  return this.setDiscountedPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.ProviderCouponDealDetailDomain.prototype.hasDiscountedPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.coconala.protobuf.domain);
