// source: domain/enumeration/service_reception_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ServiceReceptionStatus', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ServiceReceptionStatus = {
  SERVICE_RECEPTION_STATUS_UNSPECIFIED: 0,
  SERVICE_RECEPTION_STATUS_FULL_CORRESPONDENCE: 1,
  SERVICE_RECEPTION_STATUS_SUSPENDING: 2,
  SERVICE_RECEPTION_STATUS_RESERVABLE: 3
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
